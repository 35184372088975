export const CURATOR_API_URL = 'https://api.curator.io/v1';

// COOKIES
export const COOKIE_VERSIONS = {
  fr: 'ovelia FR',
  en: 'ovelia EN',
};

// BOOKINGS
export const LINK_TO_USER_BOOKING_AREA = 'https://ovelia.my.site.com/clientportal/login';

// LOCALE
export const LOCALE_FR = 'fr';
export const LOCALE_EN = 'en';
export const DEFAULT_LOCALE = LOCALE_FR;

// PHONE NUMBER
export const PHONE_NUMBER = {
  [LOCALE_EN]: '+33 1 71 25 08 20',
  [LOCALE_FR]: '01 75 27 23 23',
};

// CONTACT LINKS
export const CONTACT_FORMS_LINKS = {
  cgu: { [LOCALE_FR]: '/conditions-generales-de-vente', [LOCALE_EN]: '/terms-of-sales' },
  poc: { [LOCALE_FR]: '/politique-de-confidentialite', [LOCALE_EN]: '/private-policy' },
  collect: {
    [LOCALE_FR]: '/legal',
    [LOCALE_EN]: '/en/legal',
  },
  invest: {
    [LOCALE_FR]: 'https://www.vinci-immobilier.com/charte-de-confidentialite',
    [LOCALE_EN]: 'https://www.vinci-immobilier.com/charte-de-confidentialite',
  },
  partner: {
    [LOCALE_FR]: 'https://www.vinci-immobilier.com/partenaires-vinci',
    [LOCALE_EN]: 'https://www.vinci-immobilier.com/partenaires-vinci',
  },
};
export const CODE_REQUESTOR_CODE_TRIGGER = ['RECEVOIRBROCHURE', 'CONTACTERCONSEILLER', 'ORGANISERVISITE'];

export const LINK_USER_SPACE = 'https://ovelia.my.site.com/clientportal/login';

export const FAQ_LINK = {
  [LOCALE_FR]: 'https://ovelia.my.site.com/aide/s/',
  [LOCALE_EN]: 'https://ovelia.my.site.com/aide/s/',
};

// MAPS
export const DEFAULT_MAP_FRANCE_LOCATIONS = {
  lat: 46.864716,
  lng: 2.349014,
};
export const DEFAULT_MAP_FRANCE_BOUNDS = [
  { lat: 42.97, lng: 0.92 },
  { lat: 50.66, lng: 3.06 },
  { lat: 48.12, lng: -4.33 },
  { lat: 48.64, lng: 7.73 },
];

export const MAP_LIBRARIES = ['places'];

export const MAP_TRAVEL_MODES = {
  WALKING: 'WALKING',
  TRANSIT: 'TRANSIT',
  DRIVING: 'DRIVING',
  BICYCLING: 'BICYCLING',
};

export const MAP_TRAVEL_MODES_ICON = {
  WALKING: 'directionsWalk',
  TRANSIT: 'directionsSubway',
  DRIVING: 'directionsCar',
  BICYCLING: 'directionsBike',
};

// RESIDENCES STAY TYPES
export const MEDIUM_LONG_STAY = 'Moyen/Long Séjour';
export const SHORT_STAY = 'Court séjour';
export const CO_LIVING = 'Co-living';
// export const CO_WORKING = 'Co-working';
// export const RESTORATION = 'Restauration';

// SLUGS
export const RESIDENCE_SLUG = 'residence';
export const CONTACT_SLUG = 'contact';
export const BLOG_SLUG = 'blog';
export const CONCEPT_SLUG = 'concept';
export const HOTELS_SLUG = 'hotels';
export const STAY_SLUG = 'sejourner';

export const SLUG_FR_SENIORS_RESIDENCE = 'residences-seniors';

export const SLUG_FR_SEARCH = 'nos-residences-seniors';

export const SLUG_FR_INVEST = 'investir-residences-seniors';

export const SLUG_FR_SITEMAP = 'plan-du-site';

export const VALID_PREFIXS_SLUGS = [];

export const URL_SLUGS_RESIDENCES = {
  [LOCALE_FR]: SLUG_FR_SENIORS_RESIDENCE,
};

export const URL_SLUGS_ACTIVITIES = {
  [LOCALE_FR]: {
    [MEDIUM_LONG_STAY]: SLUG_FR_SENIORS_RESIDENCE,
  },
};

export const URL_SEARCH_PAGES = {
  [LOCALE_FR]: SLUG_FR_SEARCH,
};
export const URL_COLIVING_RESIDENCE_PAGES = {
  [LOCALE_FR]: SLUG_FR_SENIORS_RESIDENCE,
};

export const BANNED_PAGE_SLUGS = [
  BLOG_SLUG,
];

export const URL_SLUGS_INVEST = {
  [LOCALE_FR]: SLUG_FR_INVEST,
};

export const URL_SLUGS_SITEMAP = {
  [LOCALE_FR]: SLUG_FR_SITEMAP,
};
